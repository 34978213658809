export default function stringToNode(html, withWrapper = true) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = html;
    return withWrapper ? wrapper : wrapper.firstChild;
}

export function getReviewsFromJson(html) {
    const wrapper = document.createElement('ul');
    wrapper.innerHTML = html;

    return wrapper.querySelectorAll('.review-lobby--casino');
}