// Функция для отправки запроса
HTMLElement.prototype.sendBinomRequest = function () {
    const data = {
        page: document.querySelector('[data-bn-page]').getAttribute('data-bn-page'),
        type: document.querySelector('[data-bn-type]').getAttribute('data-bn-type'),
        link: this.getAttribute('data-bn-ln'),
        text: this.tagName === 'BUTTON' ? this.innerText : '',
        site: document.querySelector('[data-bn-domain]').getAttribute('data-bn-domain'),
        source: readCookie('bn_source'),
        firstPage: readCookie('bn_firstpage'),
        comId: readCookie('bn_comid'),
        lang: pageLocale
    };

    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/ajax/binom/', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 400) {
            const response = JSON.parse(xhr.responseText);
            console.log(response);
            window.location = response.url;
        } else {
            console.error("There was an error: " + xhr.statusText);
        }
    };

    xhr.onerror = function () {
        console.error("There was an error: " + xhr.statusText);
    };

    xhr.send(new URLSearchParams(data).toString());
};

// Событие клика
document.addEventListener('click', function (e) {
    const target = e.target.closest('[data-bn-ln]');

    if (target) {
        e.preventDefault();
        target.sendBinomRequest();
    }
});

// При загрузке страницы
document.addEventListener('DOMContentLoaded', function () {
    if (!readCookie('bn_source')) {
        createCookie('bn_source', document.referrer, 1);
    }

    if (!readCookie('bn_firstpage')) {
        createCookie('bn_firstpage', document.location.href, 1);
    }
    console.log(getQueryParam('comid'));

    if(getQueryParam('comid')){
        createCookie('bn_comid', getQueryParam('comid'), 1);
    }
});

// Функция для создания куки
function createCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

// Функция для чтения куки
function readCookie(name) {
    const nameEQ = encodeURIComponent(name) + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length));
    }
    return null;
}

// Функция для удаления куки
function eraseCookie(name) {
    createCookie(name, "", -1);
}

function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}
