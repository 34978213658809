export default class LangSwitcher {
    constructor() {
        this.buttons = document.querySelectorAll('.lang-pop');
        this.popup = document.getElementById('popup__change-language');
        this.closeButton = document.querySelector('[data-l-cl]')
        this.handleEvents();
    }

    handleEvents() {
        this.buttons.forEach((el) => {
            el.addEventListener('click', () => this.toggleForm())
        })

        this.closeButton.addEventListener('click', () => this.toggleForm())
    }

    toggleForm() {
        this.popup.classList.toggle('active')
    }
}