import HttpClient from "../../admin/pagebuilder/components/httpClient";
import stringToNode from "../../admin/pagebuilder/components/htmlParser";

export default class Winners {
    constructor(el) {
        this.node = el;
        this.winnersBar = this.node.querySelector('.top-rating__list');
        this.handleEvents();
    }

    handleEvents() {
        setInterval(() => {
            this.getWinner();
        }, 7000)
    }

    getWinner() {
        const client = new HttpClient();
        client.post('/ajax/winner/', {
            exclude: this.getExistingIds()
        }).then((res) => {
            const winner = stringToNode(res.winner, false);
            this.winnersBar.removeChild(this.winnersBar.lastElementChild);
            this.winnersBar.prepend(winner);
        })
    }

    getExistingIds() {
        const ids = [];
        this.node.querySelectorAll('.top-rating__list-item').forEach((el) => {
            ids.push(el.getAttribute('data-pid'));
        });

        return ids;
    }
}