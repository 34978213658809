export default class Timer{
    constructor(el) {
        this.node = el;
        this.endTime = 0;
        this.interval = null;
        this.startTimer(187966)
    }
    startTimer(duration) {
        this.endTime = Date.now() + duration * 1000;

        // Если есть сохраненное время в localStorage, восстанавливаем его
        const savedEndTime = localStorage.getItem('endTime');

        if (savedEndTime) {
           this.endTime = parseInt(savedEndTime);
        } else {
            // Сохраняем конечное время в localStorage
            localStorage.setItem('endTime', this.endTime);
        }

        // Запускаем обновление каждую секунду
        this.interval = setInterval(() => {
            this.updateTimer()
        }, 1000);
        this.updateTimer(); // Первоначальное обновление
    }

    updateTimer() {
        const now = Date.now();
        const timeLeft = Math.max(0, Math.floor((this.endTime - now) / 1000));

        const hours = Math.floor(timeLeft / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);
        const seconds = timeLeft % 60;

        // Обновляем часы, минуты и секунды на странице
        this.updateTimerDisplay(hours, minutes, seconds);

        // Когда время истекло, очищаем localStorage и останавливаем таймер
        if (timeLeft <= 0) {
            clearInterval(this.interval);
            localStorage.removeItem('endTime');
        }
    }

    // Функция для обновления отображаемых значений
    updateTimerDisplay(hours, minutes, seconds) {
        const hoursElements = this.node.querySelectorAll('.banner-img__img-time-item:nth-child(1) .banner-img__img-time-num div');
        const minutesElements = this.node.querySelectorAll('.banner-img__img-time-item:nth-child(2) .banner-img__img-time-num div');
        const secondsElements = this.node.querySelectorAll('.banner-img__img-time-item:nth-child(3) .banner-img__img-time-num div');

        hoursElements[0].textContent = Math.floor(hours / 10);
        hoursElements[1].textContent = hours % 10;

        minutesElements[0].textContent = Math.floor(minutes / 10);
        minutesElements[1].textContent = minutes % 10;

        secondsElements[0].textContent = Math.floor(seconds / 10);
        secondsElements[1].textContent = seconds % 10;
    }
}