export default class FAQ {
    constructor(el) {
        this.node = el;
        this.handleEvents();
    }

    handleEvents() {
        this.node.querySelectorAll('.faq__item').forEach((el) => {
            el.querySelector('.faq__question').addEventListener('click', () => this.toggleItem(el))
        })
    }

    toggleItem(item) {
        item.classList.toggle('active');
        const answer = item.querySelector('.faq__answer');

        if (item.classList.contains('active')) {
            answer.style.height = answer.scrollHeight + 'px';
        } else {
            answer.style.height = 0;
        }
    }
}