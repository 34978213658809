import HttpClient from "../../admin/pagebuilder/components/httpClient";

export default class TopRating {
    constructor(container) {
        this.node = container;
        this.handleEvents();
    }

    handleEvents() {
        setInterval(() => {
            this.flipGame();
        }, 3000)
    }

    flipGame() {
        const client = new HttpClient();
        client.post('/ajax/game/', {
            exclude: this.getExistingIds()
        }).then((res) => {
            const game = res.game;
            const card = this.getRandomCard();
            card.classList.add('tnolwlyk');

            setTimeout(() => {
                card.querySelector('img').src = game.image;
                card.querySelector('.top-rating__item-title').innerHTML = game.name;
            }, 1000)

            setTimeout(() => {
                card.classList.remove('tnolwlyk')
            }, 800)
        })
    }

    getExistingIds() {
        const ids = [];
        this.node.querySelectorAll('.top-rating__item').forEach((el) => {
            ids.push(el.getAttribute('data-gid'));
        })

        return ids;
    }

    getRandomCard() {
        const cards = this.node.querySelectorAll('.top-rating__item')
        const randomIndex = Math.floor(Math.random() * cards.length);
        return cards[randomIndex];
    }
}