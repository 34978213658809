import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import Winners from "./Winners";
import Winning from "./Winning";
import TopRating from "./TopRating";
import Timer from "./Timer";
import FAQ from "./FAQ";
import LangSwitcher from "./LangSwitcher";

new Swiper('#slider-main', {
    slidesPerView: 1,
    modules: [Navigation, Pagination],
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
    },
});

new Swiper('.article__img-swiper', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    loop: true,
    centeredSlides: true,
    spaceBetween: 16,
    pagination: {
        el: '.swiper-pagination',
    },
    breakpoints: {
        '500': {
            loop: false,
            spaceBetween: 0,
        },
    },
});

document.querySelectorAll('.top-rating__right').forEach((el) => {
    new Winners(el);
});

document.querySelectorAll('[data-top-games]').forEach((el) => {
    new Winning(el);
});

document.querySelectorAll('.top-rating__items').forEach((el) => {
    new TopRating(el);
});

document.querySelectorAll('.banner-img__img-time').forEach((el) => {
    new Timer(el);
})

document.querySelectorAll('.faq').forEach((faq) => {
    new FAQ(faq);
})

new LangSwitcher();

const mobNavButtons = document.querySelectorAll('.header__mob-nav-menu button');
const menuButtons = document.querySelectorAll('.header__menu.active > ul > li > button');
const closeMenuButton = document.querySelector('.header__menu-close');
const topMenuButtons = document.querySelectorAll('.header__menu-top button');

// Обработчик для кнопок мобильного навигационного меню
mobNavButtons.forEach(button => {
    button.addEventListener('click', function () {
        const nextElement = this.nextElementSibling;
        if (nextElement) {
            nextElement.classList.add('active');
        }
    });
});

// Обработчик для кнопок в активном меню
menuButtons.forEach(button => {
    button.addEventListener('click', function () {
        const nextElement = this.nextElementSibling;
        if (nextElement) {
            nextElement.classList.add('active');
        }
    });
});

// Обработчик для закрытия меню
if (closeMenuButton) {
    closeMenuButton.addEventListener('click', function () {
        const headerMenu = document.querySelector('.header__menu');
        if (headerMenu) {
            headerMenu.classList.remove('active');
        }
    });
}

// Обработчик для кнопок в верхней части меню
topMenuButtons.forEach(button => {
    button.addEventListener('click', function () {
        const headerMenu = this.closest('.header__menu');
        if (headerMenu) {
            headerMenu.classList.remove('active');
        }
    });
});